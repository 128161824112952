import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import clsx from "clsx";
import Store from "../store/store";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  Button,
  Input,
  TextField,
  IconButton,
  Tooltip,
  FormControlLabel,
  CircularProgress,
  Grid,
  MenuList,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core/";
import FrameComponent from "./FrameComponent";

const useStyles = makeStyles((theme) => ({
  paper2: {
    width: 200,
    position: "absolute",

    // float: "right",
    margin: "0px 1500px",
  },
  paper3: {
    width: 200,
    zIndex: 1000,
    float: "right",
    backgroundColor: "#f2f2f2",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "100%",
    backgroundColor: "#ddd",
  },
  buttons: {
    margin: 10,
  },
  table: {
    minWidth: 650,
  },
  bGrid: {
    border: "2px solid #ccc",
  },
  pad: {
    padding: 10,
  },
  input: {
    display: "none",
  },
}));

const Reports = observer((props) => {
  const classes = useStyles();
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const buttons = clsx(classes.buttons);
  const day = new Date().toISOString().split("T")[0];
  const [days, setDate] = useState([day, day]);
  const [htmlData, setHtmlData] = useState(null);
  const [progress, setProgress] = useState(false);

  const getData = async () => {
    setProgress(true);
    const data = await Store.getData({
      nameReport: "АнализЗаказПоставщикуСКД",
      TabType: "HTML5",
      Data1: `${days[0].replaceAll("-", "")}000000`,
      Data2: `${days[1].replaceAll("-", "")}000000`,
    });
    setHtmlData(data);
    setProgress(false);
  };

  const SelectPeriod = (props) => {
    const handleDateChange = (event, id) => {
      const arr = [...days];
      arr[id] = event.target.value;
      setDate([...arr]);
    };
    useEffect(() => {}, [Store.refresh]);

    return (
      <>
        <Input
          type="date"
          id={props.id}
          defaultValue={props.day}
          value={props.day}
          onChange={(e) => handleDateChange(e, props.id)}
          // style={{ margin: "10px 0px" }}
          className={classes.aInput}
        ></Input>
      </>
    );
  };

  return !Store.isAuthorized ? (
    <Redirect to="/login" />
  ) : (
    <Box style={{ padding: 10 }}>
      {/* <SelectPeriod id="0" day={days[0]} />
      <SelectPeriod id="1" day={days[1]} />
      <Button
        className={buttons}
        variant="contained"
        color={props.color}
        component="span"
        onClick={getData}
      >
        Get Report
      </Button> */}
      {!progress ? (
        <FrameComponent html={Store.htmlData[Store.panel]} />
      ) : (
        <CircularProgress size={38} className={classes.fabProgress} />
      )}
    </Box>
  );
});

export default Reports;
