import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Store from "../store/store";

import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
//import { Copyright } from "./header";
//import data from "../data/data";
import { observer } from "mobx-react-lite";
import axios from "axios";

const url = process.env.REACT_APP_URL_LOGIN;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//const dataFetch = new data();

const SignIn = observer(() => {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [indexButton, setIndexButton] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (Store.user !== "" && Store.pass !== "") {
      setIndexButton(1);
      axios.post(url, { login: Store.user, pass: Store.pass }).then((body) => {
        Store.isAuthorized = body.data.isIt;
        Store.currentUser = body.data.name;
        Store.token = body.data.token;

        console.log(Store.token);

        setIndexButton(0);
      });
    }
  };

  const buttons = [
    <Button
      type="paper"
      fullWidth
      variant="contained"
      className={classes.paper}
      color="primary"
      onClick={handleSubmit}
      href="#"
    >
      Autorize
    </Button>,
    <CircularProgress size={38} className={classes.fabProgress} />,
  ];

  return Store.isAuthorized ? (
    <Redirect to="/" />
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          LOGIN
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="login"
            name="email"
            autoComplete="email"
            autoFocus
            value={Store.user}
            onChange={(e) => {
              Store.user = e.target.value;
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Рassword"
            type="password"
            id="filled-password-input"
            autoComplete="current-password"
            value={Store.pass}
            onChange={(e) => {
              Store.pass = e.target.value;
            }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" style={{color: "#fbc02d"}}/>}
            label="Запомнить меня"
          /> */}
          {buttons[indexButton]}
        </form>
      </div>
    </Container>
  );
});

export default SignIn;
