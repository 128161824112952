import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Store from "../store/store";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import Reports from "./Reports";
import Period from "./Period";
import Order from "./Order";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const PanelTabs = observer(() => {
  const classes = useStyles();
  //const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    Store.panel = newValue;
    //setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={Store.panel}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Order" {...a11yProps(0)} />
          <Tab label="Receipt of goods" {...a11yProps(1)} />
          <Tab label="Sale" {...a11yProps(2)} />
          <Tab label="Rest of goods" {...a11yProps(3)} />
          <Tab label="Total" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={Store.panel} index={0}>
        <Period />
        <Order />
        <Reports />
      </TabPanel>
      <TabPanel value={Store.panel} index={1}>
        <Period />
        <Reports />
      </TabPanel>
      <TabPanel value={Store.panel} index={2}>
        <Period />
        <Reports />
      </TabPanel>
      <TabPanel value={Store.panel} index={3}>
        <Period />
        <Reports />
      </TabPanel>
      <TabPanel value={Store.panel} index={4}>
        <Period />
        <Reports />
      </TabPanel>
    </div>
  );
});
export default PanelTabs;
