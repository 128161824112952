import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Box,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import HowToRegIcon from "@material-ui/icons/HowToReg";

import { observer } from "mobx-react-lite";
import Store from "../store/store";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  tCell: {
    border: "1px solid #999",
    backgroundColor: "#f2f2f2",
    // fontSize: "8px",
  },
  tCell2: {
    border: "1px solid #999",
    // fontSize: "8px",
  },
  tField1: {
    padding: 0,
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Order = observer(() => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tCell}
                style={{ background: "#cbe4e5" }}
              >
                <Tooltip title="Reload">
                  <IconButton>
                    {/* <RefreshIcon onClick={_reload} /> */}
                  </IconButton>
                </Tooltip>
                Order
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Store.orders.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tCell2}
                >
                  <Tooltip title="Select">
                    <IconButton
                      size="small"
                      color={row === Store.currOrder ? "secondary" : "default"}
                    >
                      <HowToRegIcon
                        onClick={() => {
                          Store.currOrder = row;
                          Store.htmlData[0] = row.htmlBody;
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  {`${row.date} | ${row.nom} - ${row.summa}$`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default Order;
