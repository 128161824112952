import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignIn from "./components/Login";
import PanelTabs from "./components/PanelTabs";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={SignIn} exact />
        <Route path="/" component={PanelTabs} exact />
        <Route
          component={() => <div style={{ padding: 20 }}>Page not found</div>}
        />
      </Switch>
    </Router>
  );
};
export default App;
