import { makeAutoObservable, configure } from "mobx";
//import { makeObservable, observable, action } from 'mobx';
import axios from "axios";

configure({
  useProxies: "newer",
  enforceActions: "newer",
});

//const url1 = "https://external.idocs.kz/api/v1";

class Store {
  isAuthorized = false;
  currentUser = null;
  login = "";
  pass = "";
  token = "";
  //httpData = null;
  refresh = true;
  panel = 0;
  day = new Date().toISOString().split("T")[0];
  days = [this.day, this.day];
  htmlData = ["", "", "", ""];
  orders = [];
  currOrder = { date: "", uuid: "", nom: "", htmlBody: "", summa: "" };

  formatDate(currentDate, startEnd) {
    if (startEnd) {
      currentDate.setHours(0, 0, 0, 0);
      //  return currentDate.toISOString().replace("T", " ").slice(0, 19);
    } else {
      currentDate.setHours(23, 59, 59, 999);
    }

    return (
      currentDate.getFullYear() +
      "-" +
      ("0" + (currentDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + currentDate.getDate()).slice(-2) +
      " " +
      ("0" + currentDate.getHours()).slice(-2) +
      ":" +
      ("0" + currentDate.getMinutes()).slice(-2) +
      ":" +
      ("0" + currentDate.getSeconds()).slice(-2)
    );
  }

  async getData() {
    let url = `${process.env.REACT_APP_URL_DATA}reports-1c`;
    //"https://data.zarya.kz/srv/api/reports-1c";

    let maket = "";
    switch (this.panel) {
      case 0:
        maket = "АнализЗаказа";
        url = `${process.env.REACT_APP_URL_DATA}order-1c`;
        break;
      case 1:
        maket = "Поступление";
        break;
      case 2:
        maket = "Продажи";
        break;
      case 3:
        maket = "Остатки";
        break;
      case 4:
        maket = "ФинРезультат";
        break;
      default:
        break;
    }

    const body = {
      nameReport: "АнализЗаказПоставщикуСКД",
      TabType: "HTML5",
      maket: maket,
      order: this.currOrder.uuid,
      Data1: `${this.days[0].replaceAll("-", "")}000000`,
      Data2: `${this.days[1].replaceAll("-", "")}000000`,
      uuid: process.env.REACT_APP_UUID_CUSTOMER,
    };

    try {
      const h = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      });
      console.log(h.data);
      debugger;
      if (this.panel === 0) {
        if (h.data.length !== 0) {
          this.currOrder = h.data[0];
          this.orders = [...h.data];
          this.htmlData[0] = h.data[0].htmlBody;
        }
      } else {
        this.htmlData[this.panel] = h.data;
      }
    } catch {
      this.htmlData[this.panel] = "";
    }
    this.refresh = !this.refresh;
    //return Store.httpData;
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}

export default new Store();
