import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import clsx from "clsx";
import Store from "../store/store";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  Button,
  Input,
  TextField,
  IconButton,
  Tooltip,
  FormControlLabel,
  CircularProgress,
  Grid,
  MenuList,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core/";
//import FrameComponent from "./FrameComponent";

const useStyles = makeStyles((theme) => ({
  paper2: {
    width: 200,
    position: "absolute",

    // float: "right",
    margin: "0px 1500px",
  },
  paper3: {
    width: 200,
    zIndex: 1000,
    float: "right",
    backgroundColor: "#f2f2f2",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "100%",
    backgroundColor: "#ddd",
  },
  buttons: {
    margin: 10,
  },
  table: {
    minWidth: 650,
  },
  bGrid: {
    border: "2px solid #ccc",
  },
  pad: {
    padding: 10,
  },
  input: {
    display: "none",
  },
}));

const Period = observer((props) => {
  const classes = useStyles();
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const buttons = clsx(classes.buttons);

  //const [days, setDate] = useState(Store.days);
  const [indexButton, setIndexButton] = useState(0);
  //const [progress, setProgress] = useState(false);

  const getData = async () => {
    //setProgress(true);
    setIndexButton(1);
    const data = await Store.getData();
    setIndexButton(0);
    //setProgress(false);
  };

  const SelectPeriod = (props) => {
    const handleDateChange = (event, id) => {
      //const arr = [...days];
      Store.days[id] = event.target.value;
      //setDate([...arr]);
    };
    useEffect(() => {}, [Store.refresh]);

    return (
      <>
        <Input
          type="date"
          id={props.id}
          defaultValue={props.day}
          value={props.day}
          onChange={(e) => handleDateChange(e, props.id)}
          // style={{ margin: "10px 0px" }}
          className={classes.aInput}
        ></Input>
      </>
    );
  };

  const button = [
    <Button
      className={buttons}
      variant="contained"
      //color={props.color}
      color="primary"
      component="span"
      onClick={getData}
    >
      Get Report
    </Button>,
    <CircularProgress size={38} className={classes.fabProgress} />,
  ];

  return !Store.isAuthorized ? (
    <Redirect to="/login" />
  ) : (
    <Box style={{ padding: 10 }}>
      <SelectPeriod id="0" day={Store.days[0]} />
      <SelectPeriod id="1" day={Store.days[1]} />
      {button[indexButton]}
      {`${Store.currOrder.nom} - ${Store.currOrder.summa}$`}
    </Box>
  );
});

export default Period;
